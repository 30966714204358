<template>
  <div class="row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <c-card title="MSDS개정" class="cardClassDetailForm revisionLayout" topClass="topcolor-orange">
        <template slot="card-select">
          <!-- 개정이력 -->
          <c-select
            :editable="!isRevision"
            :comboItems="revs"
            type="custom"
            typetext="개정이력"
            itemText="revisionNum"
            itemValue="mdmChemMaterialMsdsId"
            label=""
            name="selectedId"
            v-model="selectedId"
            @input="rowClickRev"
          ></c-select>
        </template>
        <template slot="card-button">
          <q-btn-group outline >
            <!-- 개정 -->
            <c-btn
              v-show="editable && !isRevision"
              label="LBLREV"
              icon="restart_alt"
              @btnClicked="setRevision" />
            <!-- 개정취소 -->
            <c-btn
              v-show="editable && isRevision"
              label="LBLREVCANCEL"
              icon="restart_alt"
              @btnClicked="cancelRevision" />
            <c-btn
              v-show="editable && isRevision"
              :url="saveUrl"
              :isSubmit="isSave"
              :param="chemData"
              mappingType="POST"
              label="LBLSAVE"
              icon="save"
              @beforeAction="saveInfo"
              @btnCallback="saveCallback" />
          </q-btn-group>
        </template>
        <template slot="card-detail">
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
            <div class="row">
              <div class="col-1">
                <!-- 개정번호 -->
                <c-text
                  :required="editable && isRevision"
                  :editable="editable && isRevision"
                  label="LBLREVNO"
                  name="revisionNum"
                  v-model="chemData.revisionNum">
                </c-text>
              </div>
              <div class="col-2">
                <!-- 개정일시 -->
                <c-text
                  :editable="false"
                  label="LBLREVPERIOD"
                  name="msdsRegDtStr"
                  v-model="chemData.msdsRegDtStr">
                </c-text>
              </div>
              <div class="col-1">
                <!-- 개정자 -->
                <c-text
                  :editable="false"
                  label="LBLREVUSER"
                  name="msdsRegUserName"
                  v-model="chemData.msdsRegUserName">
                </c-text>
              </div>
              <div class="col-2">
                <!-- MSDS번호 -->
                <c-text
                  :editable="editable && isRevision"
                  label="MSDS번호"
                  name="msdsNo"
                  v-model="chemData.msdsNo">
                </c-text>
              </div>
              <div class="col-6">
                <!-- 개정사유 -->
                <c-text
                  :required="editable && isRevision"
                  :editable="editable && isRevision"
                  label="LBLREVREASON"
                  name="revisionContent"
                  v-model="chemData.revisionContent">
                </c-text>
              </div>
            </div>
          </div>
        </template>
      </c-card>
    </div>
    <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
      <c-card title="MSDS 내부용" class="cardClassDetailForm">
        <template slot="card-detail">
          <div class="col-12">
            <c-upload
              :attachInfo="attachInfo"
              :editable="editable && revEditable"
              label="MSDS 내부용 첨부파일">
            </c-upload>
          </div>
        </template>
      </c-card>
    </div>
    <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
      <c-card title="MSDS 업체용" class="cardClassDetailForm">
        <template slot="card-detail">
          <div class="col-12">
            <c-upload
              :attachInfo="attachInfo2"
              :editable="editable && revEditable"
              label="MSDS 업체용 첨부파일">
            </c-upload>
          </div>
        </template>
      </c-card>
    </div>
  </div>
</template>

<script>
import { uid } from 'quasar'
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'chem-msdsfile',
  props: {
    param: {
      type: Object,
      default: () => ({
        mdmChemMaterialId: '',
      }),
    },
    chemData: {
      type: Object,
      default: () => ({
        plantCd: null,  // 사업장코드
        mdmChemMaterialId: '',  // 화학자재 일련번호
        mdmChemMaterialMsdsId: '',
        revisionNum: '',
        groupId: '',
        sysRevision: 1,
        revisionContent: '제정',
        preMdmChemMaterialId: '',  // 이전 화학자재 일련번호
        materialCd: '',  // 자재코드
        mdmChemMaterialName: '',  // 화학자재 품명
        deliveryVendorCd: '',  // 협력사명
        mfgVendorCd: '',  // 제조사명
        usageCd: null,  // 용도
        usageEtc: '',  // 용도 기타
        inUnitCd: null,  // 입고단위
        inConversonFactor: '',  // 입고환산계수
        inConversonUnitCd: '',  // 입고환산단위
        msdsNo: '',  // MSDS번호
        emergTel: '',  // 긴급연락처
        makeAddress: '',  // 제조사주소
        specificGravity: '',  // 비중
        propertiesStateCd: null,  // 성상
        colorClass: '',  // 색상
        warehousingTypeCd: null,  // 입고형태
        freezingPoint: '',  // 어는점
        boilPoint: '',  // 끓는점
        flashPoint: '',  // 인화점
        firePoint: '',  // 발화점
        explosionLimit: '',  // 폭발한계상한하한
        vaporPressure: '',  // 증기압
        twaStandart: '',  // 노출기준TWA
        characteristicValue: '',  // 특성치(경구경치흡입제외)
        remarks: '',  // 비고
        useFlag: 'Y',  // 사용여부
        unusedReason: '', // 미사용 사유
        regUserId: '',  // 등록자 ID
        chgUserId: '',  // 수정자 ID
        depts: '', // 사용반
      }),
    },
  },
  data() {
    return {
      revs: [],
      savedMdmChemMaterialMsdsId: '',
      editable: true,
      isSave: false,
      isRevision: false,
      detailUrl: '',
      revListUrl: '',
      revVersionUrl: '',
      saveUrl: '',
      selectedId: null,
      attachInfo: {
        isSubmit: '',
        taskClassCd: 'CHEM_MSDS',
        taskKey: '',
        beforeTaskKey: '',
        isRev: false,
      },
      attachInfo2: {
        isSubmit: '',
        taskClassCd: 'CHEM_MSDS_VENDOR',
        taskKey: '',
        beforeTaskKey: '',
        isRev: false,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    revEditable() {
      return this.selectedId === this.savedMdmChemMaterialMsdsId
    }
  },  
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.detailUrl = selectConfig.mdm.mam.chem.msdsUnit.get.url;
      this.revListUrl = selectConfig.mdm.mam.chem.msdsUnit.rev.url;
      this.revVersionUrl = selectConfig.com.revision.getversion
      this.saveUrl = transactionConfig.mdm.mam.chem.msdsUnit.insert.url;

      this.$set(this.$data, 'selectedId', this.chemData.mdmChemMaterialMsdsId)
      this.$set(this.$data, 'savedMdmChemMaterialMsdsId', this.chemData.mdmChemMaterialMsdsId)
      this.$set(this.attachInfo, 'taskKey', this.chemData.mdmChemMaterialMsdsId)
      this.$set(this.attachInfo2, 'taskKey', this.chemData.mdmChemMaterialMsdsId)

      this.getRevList(this.chemData.groupId);
    },
    getDetail(id, check) {
      let _id = id ? id : this.chemData.mdmChemMaterialMsdsId
      this.$http.url = this.$format(this.detailUrl, _id);
      this.$http.type = 'GET';
      this.$http.request((_result) => {
        this.$_.extend(this.chemData, {
          mdmChemMaterialMsdsId: _result.data.mdmChemMaterialMsdsId,
          revisionNum: _result.data.revisionNum,
          groupId: _result.data.groupId,
          sysRevision: _result.data.sysRevision,
          msdsNo: _result.data.msdsNo,
          revisionContent: _result.data.revisionContent,
          msdsRegDtStr: _result.data.msdsRegDtStr,
          msdsRegUserName: _result.data.msdsRegUserName,
        })

        if (check) {
          this.getRevList(_result.data.groupId);
        }
      },);
    },
    getRevList(_groupId) {
      this.$http.url = this.revListUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        groupId: _groupId
      };
      this.$http.request((_result) => {
        this.revs = _result.data;
      },);
    },
    setRevision() {
      this.$http.url = this.revVersionUrl;
      this.$http.param = {
        revisionNum: this.chemData.revisionNum
      }
      this.$http.type = 'GET';
      this.$http.request((_result) => {
        this.chemData.revisionNum = _result.data;
        this.chemData.revisionContent = '';
        this.chemData.regDtStr = '';
        this.chemData.regUserName = '';
        this.isRevision = true;
        /**
         * 첨부파일 셋팅
         */
        this.$set(this.attachInfo, 'taskKey', '')
        this.$set(this.attachInfo, 'beforeTaskKey', this.chemData.mdmChemMaterialMsdsId)
        this.$set(this.attachInfo, 'isRev', true)
        this.$set(this.attachInfo2, 'taskKey', '')
        this.$set(this.attachInfo2, 'beforeTaskKey', this.chemData.mdmChemMaterialMsdsId)
        this.$set(this.attachInfo2, 'isRev', true)
      });
    },
    cancelRevision() {
      this.isRevision = false;
      /**
       * 첨부파일 셋팅
       */
      this.$set(this.attachInfo, 'taskKey', this.chemData.mdmChemMaterialMsdsId)
      this.$set(this.attachInfo, 'beforeTaskKey', '')
      this.$set(this.attachInfo, 'isRev', false)
      this.$set(this.attachInfo2, 'taskKey', this.chemData.mdmChemMaterialMsdsId)
      this.$set(this.attachInfo2, 'beforeTaskKey', '')
      this.$set(this.attachInfo2, 'isRev', false)

      this.selectedId = this.chemData.mdmChemMaterialMsdsId;
      this.getDetail(this.selectedId, false)
    },
    rowClickRev(row) {
      if (row && row != this.chemData.mdmChemMaterialMsdsId) {
        this.selectedId = row;
        this.getDetail(this.selectedId, false)
        this.$set(this.attachInfo, 'taskKey', this.selectedId)
        this.$set(this.attachInfo2, 'taskKey', this.selectedId)
      }
    },
    saveInfo() {
      window.getApp.$emit('CONFIRM', {
        title: 'LBLCONFIRM',
        message: '현재버전을 개정하여 저장하시겠습니까?',
        // TODO : 필요시 추가하세요.
        type: 'info', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.chemData.regUserId = this.$store.getters.user.userId;
          this.chemData.sysRevision = parseInt(this.chemData.sysRevision) + 1;

          this.isSave = !this.isSave;
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    saveCallback(result) {
      window.getApp.$emit('APP_REQUEST_SUCCESS');

      /**
       * 첨부파일 셋팅
       */
      this.$set(this.attachInfo, 'taskKey', result.data)
      this.$set(this.attachInfo, 'isSubmit', uid())
      this.$set(this.attachInfo, 'beforeTaskKey', '')
      this.$set(this.attachInfo, 'isRev', false)
      this.$set(this.attachInfo2, 'taskKey', result.data)
      this.$set(this.attachInfo2, 'isSubmit', uid())
      this.$set(this.attachInfo2, 'beforeTaskKey', '')
      this.$set(this.attachInfo2, 'isRev', false)

      this.isRevision = false;
      this.selectedId = result.data;
      this.getDetail(result.data, true)
    },
  }
};
</script>